<!--
 * @Author: 许波
 * @Date: 2021-07-26 23:08:29
 * @LastEditTime: 2022-11-25 22:13:23
-->
<template>
  <div class="entrance-marange-detail-wrap">
    <div class="module-wrap pbn">
      <el-form :inline="true" :label-position="labelPosition" label-width="90px" :model="formData">
        <div class="input-area flex fsbc">
          <div>
            <el-form-item label="年份">
              <el-select class="w140" v-model="formData.year" placeholder="请选择年份">
                <el-option :label="year.label"
                :value="year.value" v-for="year in years"
                :key="year.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="月份">
              <el-select class="w140" v-model="formData.month" clearable
              @change="getDays" placeholder="请选择月份">
                <el-option :label="month.label"
                :value="month.value" v-for="month in months"
                :key="month.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="日期">
              <el-select  class="w140" v-model="formData.day" clearable placeholder="请选择日期">
                <el-option :label="day.label"
                :value="day.value" v-for="day in days"
                :key="day.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="运输状态">
              <el-select  class="w140" v-model="formData.complete" placeholder="请选择状态">
                <el-option :label="item.label" v-for="item in transportState"
                :key="item.val" :value="item.val"/>
              </el-select>
            </el-form-item>
            <el-form-item label="发出项目">
              <el-select  class="w140" v-model="formData.projectUuid" placeholder="请选择项目">
                <el-option :label="item.name" v-for="item in proList"
                :key="item.uuid" :value="item.uuid"/>
              </el-select>
            </el-form-item>
            <el-form-item label="弃土场">
              <el-select  class="w140" v-model="formData.qtcUuid" placeholder="请选择弃土场">
                <el-option :label="item.name" v-for="item in qtcList"
                :key="item.uuid" :value="item.uuid"/>
              </el-select>
            </el-form-item>
            <el-form-item label="车牌号">
              <el-input v-model="formData.carLicense" placeholder="请输入车牌号" class="w140"/>
            </el-form-item>
            <el-form-item label="运输编号">
              <el-input v-model="formData.couponNo" placeholder="请输入运输编号" class="w140"/>
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <el-button type="primary" @click="search">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain @click="resetData">重置</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="module-wrap">
      <el-table
        :data="tableData"
        border
        show-summary
        :summary-method="getSummaries"
        :header-cell-style="$rowClass"
        style="width: 100%"
      >
        <el-table-column prop="couponNo" label="运输编号" />
        <el-table-column prop="carLicense" label="车牌号" />
        <el-table-column prop="carCapacity" label="弃土方量" />
        <el-table-column prop="projectName" label="出发项目" />
        <el-table-column prop="sgfName" label="施工方" />
        <el-table-column :formatter="formatStartTime" label="出发时间" />
        <el-table-column :formatter="formatEndTime" label="到达时间" />
        <el-table-column :formatter="formatComplete" label="状态" />
      </el-table>
      <div class="pagination-block">
        <el-pagination
          background
          :current-page.sync="page"
          @current-change="handlerCurPage"
          :total="count"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  createYears,
  createMonths,
  createDays,
  globalClose,
  formatDate,
} from '../../utils/index';
import {
  $gp_qtcTransList, $gp_getQtcList, $gp_getProList, $gp_transListTotal,
} from '../../api/index';

export default {
  name: 'EntraceRecored',
  data() {
    return {
      labelPosition: 'right',
      count: 5,
      page: 1,
      formData: {
        page: 0,
        size: 10,
      },
      tableData: [],
      proList: [],
      qtcList: [],
      days: [],
      years: [],
      months: [],
      transportState: [
        { label: '全部', val: null },
        { label: '运输中', val: false },
        { label: '已完成', val: true },
      ],
    };
  },
  created() {
    this.years = createYears(5);
    this.months = createMonths();
    this.formData.year = this.years[0].value;
    this.getQtcList();
    this.getProList();
    this.groupTransList();
    this.qtcTransStatistics();
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  methods: {
    search() {
      console.log('search');
      const { carLicense, couponNo } = this.formData;
      if (!carLicense) delete this.formData.carLicense;
      if (!couponNo) delete this.formData.couponNo;
      this.groupTransList();
    },
    handlerCurPage(val) {
      this.formData.page = val - 1;
      this.groupTransList();
    },
    getQtcList() {
      $gp_getQtcList({ size: 100 }).then((res) => {
        this.qtcList = res.list;
        this.count = res.totalCount;
      });
    },
    resetData() {
      this.formData = {
        page: 0,
        size: 10,
      };
    },
    getProList() {
      $gp_getProList({ size: 100 }).then((res) => {
        this.proList = res.list;
      });
    },

    handleSizeChange(val) {
      console.log(val);
    },
    formatStartTime({ startTime }) {
      return formatDate(startTime);
    },
    formatEndTime({ endTime }) {
      return formatDate(endTime);
    },
    formatComplete({ complete }) {
      console.log(complete);
      return complete ? '完成' : '运输中';
    },
    getDays() {
      this.days = createDays(this.formData.month, this.formData.year);
    },
    groupTransList() {
      $gp_qtcTransList(this.formData).then((res) => {
        this.tableData = res.list;
      });
    },
    qtcTransStatistics() {
      $gp_transListTotal(this.formData).then((res) => {
        this.total = res.capacity;
        console.log('res', res);
      });
    },
    getSummaries() {
      return ['总计', '-', this.total];
    },
  },
};
</script>

<style lang="scss" scoped>
.input-area {
  width: 1100px;
}
</style>
