<!--
 * @Author: 许波
 * @Date: 2021-07-13 20:11:20
 * @LastEditTime: 2023-02-15 23:40:46
-->
<template>
  <div class="module-wrap" v-if="$route.name === 'AddProject'">
     <el-form label-width="100px" :rules="rules" :model="formData">
       <div class="flex">
          <div class="flex-item">
            <el-form-item label="项目名称：" prop="projectName">
              <el-input v-model="formData.projectName"></el-input>
            </el-form-item>
            <el-form-item label="联系人：">
              <el-input v-model="formData.person"></el-input>
            </el-form-item>
            <el-form-item label="经度：" prop="lng">
              <el-input v-model="formData.lng" @blur="handlerChange"></el-input>
            </el-form-item>
            <el-form-item label="项目状态：" prop="status">
              {{ formatState(formData.projectStatus) }}
                <!-- <el-radio-group v-model="formData.status">
                  <el-radio :label="'BUILDING'">建设中</el-radio>
                  <el-radio :label="'COMPLETED'">已完工</el-radio>
                </el-radio-group> -->
            </el-form-item>
          </div>
          <div class="flex-item">
            <el-form-item label="登陆账号：" prop="username">
              <el-input v-model="formData.username"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
              <el-input v-model="formData.phone"></el-input>
            </el-form-item>
            <el-form-item label="纬度：" prop="lat">
              <el-input v-model="formData.lat" @blur="handlerChange"></el-input>
            </el-form-item>
            <el-form-item label="项目类型：">
              {{formData.projectType == 1 ? '出土项目' : '进土项目'}}
              <!-- <el-input v-model="formData.customer" maxlength="100"></el-input> -->
            </el-form-item>
          </div>
       </div>
       <el-form-item label="客户单位：" prop="customer">
          <el-input v-model="formData.customer" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item label="项目地址：" prop="address">
          <el-input v-model="formData.address"></el-input>
        </el-form-item>
        <Gmap ref='gmap' @handlePosition="handlePosition"></Gmap>
        <div class="flex fcc">
          <el-form-item class="mt20">
            <el-button type="primary" @click="createPro">确定</el-button>
            <el-button @click="$router.back()">取消</el-button>
          </el-form-item>
        </div>
      </el-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Gmap from '../../components/Gmap.vue';
import {
  $gp_createPro, $gp_getProInfo, $gp_editProInfo,
} from '../../api/index';
import { globalClose } from '../../utils/utils';

export default {
  name: 'AddProject',
  components: {
    Gmap,
  },
  data() {
    return {
      formData: {
        lng: '',
        lat: '',
        projectType: 1,
      },
      rules: {
        projectName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        username: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
        lat: [{ required: true, message: '请输入纬度', trigger: 'blur' }],
        lng: [{ required: true, message: '请输入经度', trigger: 'blur' }],
        customer: [{ required: true, message: '请输入客户单位', trigger: 'blur' }],
        address: [{ required: true, message: '请输入项目地址', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters(['updateTime']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  created() {
    const { projectUuid } = this.$route.query;
    if (projectUuid) {
      this.getProInfo(projectUuid);
    }
  },

  methods: {
    handlerChange() {
      if (this.formData.lng && this.formData.lat) {
        this.$nextTick(() => {
          const position = [this.formData.lng, this.formData.lat];
          console.log(position);
          this.$refs.gmap.initMapToMarker(position, this.address);
        });
      }
    },
    validate() {
      const fields = Object.keys(this.rules);
      for (let i = 0; i < fields.length; i += 1) {
        if (!this.formData[fields[i]]) {
          this.$message.error(this.rules[fields[i]][0].message);
          return false;
        }
      }
      return true;
    },
    createPro() {
      const checkResult = this.validate();
      if (!checkResult) return;
      if (this.formData.projectUuid) {
        $gp_editProInfo(this.formData).then(() => {
          this.$storage.remove('proList');
          this.$parent.getProList();
          this.$router.back();
        });
      } else {
        $gp_createPro(this.formData).then(() => {
          this.$storage.remove('proList');
          this.$parent.getProList();
          this.$router.back();
        });
      }
    },
    handlePosition(val) {
      console.log('handle', val);
      // const { lng, lat } = val;
      // this.formData.lng = lng;
      // this.formData.lat = lat;
    },
    formatState(state) {
      const status = {
        1: '建设中',
        2: '已暂停',
        3: '已完工',
      };
      return status[state];
    },

    getProInfo(projectUuid) {
      $gp_getProInfo({ projectUuid }).then((res) => {
        console.log(res);
        this.formData = res;
        const { address, lng, lat } = res;
        console.log(address, lng, lat);
        this.address = address;
        this.position = [lng, lat];
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.gmap.initMapToMarker(this.position, address);
        });
      });
    },

  },
};
</script>

<style lang="scss" scoped>
  .t-gd-custom-map {
    height: 300px !important;
    width: 100%;
    .amap-marker-label {
      color: #ff4739 !important;
      border: none !important;
      background-color: transparent !important;
      font-size: 0.8em !important;
    }
    .t-amap-info-window {
      background-color: white;

      p {
        margin: 0;
        color: #adadad;
        span:last-child {
          font-size: 0.8em;
        }
      }
    }
  }
</style>
