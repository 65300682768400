<!--
 * @Author: 许波
 * @Date: 2021-07-13 20:11:20
 * @LastEditTime: 2023-02-02 23:19:55
-->
<template>
  <div class="module-wrap" v-if="$route.name === 'AddProjectSubAccount'">
    <div class="flex">
      <div class="left">
        <div class="flex fsbc">
          <p class="title">{{formData.projectName}}</p>
          <el-button @click="addSubAccount">添加</el-button>
        </div>
        <el-table
          class="mt20"
          border
          :data="tableData"
          :header-cell-style="$rowClass"
          style="width: 100%">
          <el-table-column prop="subUsername" label="子账号" />
          <el-table-column prop="lng" width="80" label="经度" />
          <el-table-column prop="lat" width="80" label="纬度" />
          <el-table-column prop="address" label="位置" />
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <div class="operation-btns">
                <span class="theme-color pointer"
                @click="delPro(scope.row.subUuid)">删除</span>
                <span class="theme-color pointer"
                @click="resetPwd(scope.row.subUuid)">重置密码</span>
                <span class="theme-color pointer"
                @click="seeDetail(scope.row)">详情</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-form ref="subData" label-width="100px" :rules="rules" :model="subData">
        <el-form-item label="登陆账号" prop="username" required>
          <el-input v-model="subData.username"></el-input>
        </el-form-item>
        <div class="flex">
           <div class="flex-item">
             <el-form-item label="经度" prop="lng" required>
               <el-input v-model="subData.lng" @blur="handlerChange"></el-input>
             </el-form-item>
           </div>
           <div class="flex-item">
             <el-form-item label="纬度" prop="lat" required>
               <el-input v-model="subData.lat" @blur="handlerChange"></el-input>
             </el-form-item>
           </div>
        </div>
         <el-form-item label="位置描述" prop="address">
           <el-input v-model="subData.address"></el-input>
         </el-form-item>
         <Gmap ref='gmap' @handlePosition="handlePosition"></Gmap>
         <div class="flex">
          <div class="flex-item">
            <el-form-item label="更新时间" prop="address">
              {{subData.refreshTime}}
            </el-form-item>
          </div>
          <div class="flex-item">
            <el-form-item label="操作人员" prop="address">
              {{subData.refreshBy}}
            </el-form-item>
          </div>
         </div>
         <div class="flex fcc">
           <el-form-item class="mt20">
             <el-button type="primary"
             v-if="!subData.subUuid" @click="createPro">确定</el-button>
             <el-button @click="$router.back()">返回</el-button>
           </el-form-item>
         </div>
       </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Gmap from '../../components/Gmap.vue';
import {
  $gp_getProInfo, $gp_proCreateSub, $gp_proSubList, $gp_proSubDel, $gp_proResetSubPwd,
} from '../../api/index';
import { globalClose, deepClone } from '../../utils/utils';

export default {
  name: 'AddProject',
  components: {
    Gmap,
  },
  data() {
    return {
      tableData: [],
      formData: {
        lng: '',
        lat: '',
      },
      subData: {
        lng: '',
        lat: '',
      },
      rules: {
        username: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
        lat: [{ required: true, message: '请输入纬度', trigger: 'blur' }],
        lng: [{ required: true, message: '请输入经度', trigger: 'blur' }],
        address: [{ required: true, message: '请输入项目地址', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters(['updateTime']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  created() {
    const { projectUuid } = this.$route.query;
    if (projectUuid) {
      this.projectUuid = projectUuid;
      this.getProInfo();
      this.getSubAccount();
    }
  },

  methods: {
    handlerChange() {
      if (this.subData.lng && this.subData.lat) {
        this.$nextTick(() => {
          const position = [this.subData.lng, this.subData.lat];
          console.log(position);
          this.$refs.gmap.initMapToMarker(position, this.address);
        });
      }
    },
    validate() {
      const fields = Object.keys(this.rules);
      for (let i = 0; i < fields.length; i += 1) {
        if (!this.subData[fields[i]]) {
          this.$message.error(this.rules[fields[i]][0].message);
          return false;
        }
      }
      return true;
    },
    createPro() {
      const checkResult = this.validate();
      if (!checkResult) return;
      this.subData.projectUuid = this.projectUuid;
      $gp_proCreateSub(this.subData).then(() => {
        this.$message.success('添加成功');
        this.getSubAccount();
        this.$refs.subData.resetFields();
      });
    },
    handlePosition(val) {
      console.log('handle', val);
    },
    getSubAccount() {
      const { projectUuid } = this;
      $gp_proSubList({ projectUuid }).then((res) => {
        this.tableData = res;
      });
    },
    getProInfo() {
      const { projectUuid } = this;
      $gp_getProInfo({ projectUuid }).then((res) => {
        console.log(res);
        this.formData = res;
        const { address, lng, lat } = res;
        console.log(address, lng, lat);
        this.address = address;
        this.position = [lng, lat];
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.gmap.initMapToMarker(this.position, address);
        });
      });
    },
    delPro(subUuid) {
      this.$confirm('你确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $gp_proSubDel({ subUuid }).then(() => {
          this.$message.success('删除成功');
          this.getSubAccount();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作',
        });
      });
    },
    seeDetail(detail) {
      console.log(detail);
      this.subData = deepClone(detail);
      this.subData.username = detail.subUsername;
    },
    addSubAccount() {
      this.subData = {};
      this.$refs.subData.resetFields();
    },
    resetPwd(subUuid) {
      const msg = '你确认要重置密码吗?';
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $gp_proResetSubPwd({ subUuid }).then(() => {
          this.$message.success('重置密码成功');
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置密码',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .t-gd-custom-map {
    height: 300px !important;
    width: 100%;
    .amap-marker-label {
      color: #ff4739 !important;
      border: none !important;
      background-color: transparent !important;
      font-size: 0.8em !important;
    }
    .t-amap-info-window {
      background-color: white;

      p {
        margin: 0;
        color: #adadad;
        span:last-child {
          font-size: 0.8em;
        }
      }
    }
  }
</style>
