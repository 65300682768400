<!--
 * @Author: 许波
 * @Date: 2021-07-10 12:43:50
 * @LastEditTime: 2023-02-16 00:31:09
-->
<template>
  <div class="main module-wrap">
    <el-form :disabled="isNotEdit" :label-position="labelPosition" label-width="120px"
      :model="formData">
      <div label-width="120px"
        :model="formData" class="head-input flex fsbc">
        <div class="input-item">
          <el-form-item label="项目名称：" prop="projectUuid" required>
            <ProSelect :width="200" :scene="1"
            v-model="formData.projectUuid" @handlerInfo="hadnlerProInfo" />
          </el-form-item>
          <el-form-item label="当前状态：">
            {{formatStatus(formData)}}
          </el-form-item>
          <el-form-item label="当前类型：">
            {{formatType(formData)}}
          </el-form-item>
          <el-form-item label="当前余票：">
            {{formData.remainCoupon}}
          </el-form-item>
        </div>
        <div class="input-item">
          <el-form-item label="客户单位：">
            {{formData.customer}}
          </el-form-item>
          <el-form-item label="修改后状态：" prop="newStatus" required>
            <el-select v-model="formData.newStatus">
                <el-option
                  v-for="state in status"
                  :value="state.value"
                  :label="state.label"
                  :key="state.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="修改后类型：" prop="newType" required>
              <el-select v-model="formData.newType">
                <el-option
                  v-for="state in types"
                  :value="state.value"
                  :label="state.label"
                  :key="state.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="余票清零：" prop="clearCoupon" required>
              <el-radio-group v-model="formData.clearCoupon">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
        </div>
      </div>
      <el-form-item label="备注：">
          <el-input
            v-model="formData.remark"
            placeholder="请在此备注修改项目状态或项目类型的原因"
            maxlength="50"
            show-word-limit
            type="text" />
        </el-form-item>
      <div>
        <div style="padding-left:54px">
          <UploadFile :list="fileList" @onChange="onChange"/>
        </div>
        <!-- <div class="approve-info flex">
          <el-form-item class="mr200" label="审批人员：">{{formData.refreshBy}} </el-form-item>
          <el-form-item label="审批时间：">{{formData.refreshTime}}</el-form-item>
        </div> -->
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { globalClose } from '../../utils/utils';
import {
  $gp_proApplyDetail, $gp_proApplyCreate,
} from '../../api/index';
import UploadFile from '../../components/UploadFile.vue';
import ProSelect from '../../components/ProSelect.vue';

export default {
  name: 'CreateProApply',
  components: { UploadFile, ProSelect },
  data() {
    return {
      labelPosition: 'right',
      formData: { },
      applyUuid: '',
      isNotEdit: false,
      status: [
        { value: 1, label: '建设中' },
        { value: 2, label: '已暂停' },
        { value: 3, label: '已完工' },
      ],
      types: [
        { value: 1, label: '出土项目' },
        { value: 2, label: '进土项目' },
      ],
      fileList: [],
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
  },
  watch: {
    updateTime(val) {
      if (val.tag === 'refresh') { this.refreshData(); }
      if (val.tag === 'close') { globalClose(this); }
      if (val.tag === 'onCancel') { this.$router.back(); }
      if (val.tag === 'onConfirm') { this.approveByUuid(); }
    },
  },
  created() {
    console.log('this.$route.params', this.$route.params);
    const { applyUuid } = this.$route.query;

    this.isNotEdit = Boolean(applyUuid);
    if (this.isNotEdit) {
      this.applyUuid = applyUuid;
      this.getProApplyDetail();
    }
  },
  methods: {
    getProApplyDetail() {
      $gp_proApplyDetail({ applyUuid: this.applyUuid }).then((res) => {
        console.log(res);
        this.formData = res;
      });
    },
    /**
     * @description: 获取选中项目信息
     * @param {*} val
     * @return {*}
     */
    hadnlerProInfo(val) {
      console.log(val);
      this.formData = val;
    },
    formatStatus({ projectStatus }) {
      const state = {
        1: '建设中',
        2: '已暂停',
        3: '已完工',
      };
      return state[projectStatus] || '--';
    },
    formatType({ projectType }) {
      const type = {
        1: '出土项目',
        2: '进土项目',
      };
      return type[projectType] || '--';
    },
    onChange(val) {
      this.fileList = val;
    },
    approveByUuid() {
      if (!this.formData.projectUuid) {
        this.$message.warning('请选择项目');
        return;
      }
      if (!this.formData.newStatus) {
        this.$message.warning('请选择更新状态');
        return;
      }
      if (!this.formData.newType) {
        this.$message.warning('请选择更新类型');
        return;
      }
      if (this.formData.clearCoupon === undefined) {
        this.$message.warning('请选择是否余票是否清零');
        return;
      }
      this.formData.fileList = this.fileList;
      console.log('this.formData', this.formData);
      $gp_proApplyCreate(this.formData).then((res) => {
        console.log('res', res);
        this.$parent.getProList();
        this.$router.back();
      });
    },
    refreshData() {
      this.getProApplyDetail();
    },
  },
};
</script>

<style lang="scss" scoped>
  .input-item{
    width: 48%;
  }
  .submit-area{
    padding: 20px;
    text-align: center;
  }
</style>
