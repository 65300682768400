<!--
 * @Author: 许波
 * @Date: 2021-07-10 12:43:50
 * @LastEditTime: 2023-02-16 00:31:58
-->
<template>
  <div class="main module-wrap">
    <el-form :label-position="labelPosition" label-width="120px"
      :model="formData">
      <div label-width="120px"
        :model="formData" class="head-input flex fsbc">
        <div class="input-item">
          <el-form-item label="项目名称：" prop="projectUuid" required>
            {{formData.projectName}}
          </el-form-item>
          <el-form-item label="当前状态：">
            {{formatStatus(formData.oldStatus)}}
          </el-form-item>
          <el-form-item label="当前类型：">
            {{formatType(formData.oldType)}}
          </el-form-item>
          <el-form-item label="当前余票：">
            {{formData.remainCoupon}}
          </el-form-item>
        </div>
        <div class="input-item">
          <el-form-item label="客户单位：">
            {{formData.customer}}
          </el-form-item>
          <el-form-item label="修改后状态：" prop="newStatus" required>
            {{formatStatus(formData.newStatus)}}
            </el-form-item>
            <el-form-item label="修改后类型：" prop="newType" required>
              {{formatType(formData.newType)}}
            </el-form-item>
            <el-form-item label="余票清零：" prop="clearCoupon" required>
              {{formData.couponClear ? '是' : '否'}}
            </el-form-item>
        </div>
      </div>
      <el-form-item label="备注：">
          {{formData.remark}}
        </el-form-item>
      <div>
        <UploadFile :scene="2" :list="formData.files"/>
        <div class="approve-info flex">
          <div class="input-item">
            <el-form-item label="申请时间：">{{formData.createTime}}</el-form-item>
            <el-form-item label="审批时间：">{{formData.approveTime}}</el-form-item>
          </div>
          <div class="input-item">
            <el-form-item class="mr200" label="申请人员：">{{formData.createBy}} </el-form-item>
            <el-form-item class="mr200" label="审批人员：">{{formData.approveBy}} </el-form-item>
          </div>
        </div>
        <el-form-item label="审批结果：">
          <span v-if="isOnlyBack">{{ formatState(formData.applyStatus) }}</span>
          <el-select v-else v-model="formData.applyPass">
            <el-option
              v-for="state in resultState"
              :value="state.value"
              :label="state.label"
              :key="state.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="审批意见：">
          <span v-if="isOnlyBack">{{ formData.applyComment }}</span>
          <el-input
            v-else
            v-model="formData.applyComment"
            placeholder="请输入审批备注（未通过时必填）"
            maxlength="50"
            show-word-limit
            type="text" />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { globalClose } from '../../utils/utils';
import {
  $gp_proApplyDetail, $gp_proApply,
} from '../../api/index';
import UploadFile from '../../components/UploadFile.vue';

export default {
  name: 'CheckProApply',
  components: { UploadFile },
  data() {
    return {
      labelPosition: 'right',
      formData: {
        applyPass: false,
      },
      applyUuid: '',
      isNotEdit: false,
      status: [
        { value: 1, label: '建设中' },
        { value: 2, label: '已暂停' },
        { value: 3, label: '已完工' },
      ],
      types: [
        { value: 1, label: '出土项目' },
        { value: 2, label: '进土项目' },
      ],
      resultState: [
        { value: true, label: '已通过' },
        { value: false, label: '未通过' },
      ],
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
    isOnlyBack() {
      return Boolean(this.$route.query.onlyBack);
    },
  },
  watch: {
    updateTime(val) {
      if (val.tag === 'refresh') { this.refreshData(); }
      if (val.tag === 'close') { globalClose(this); }
      if (val.tag === 'onCancel') { this.$router.back(); }
      if (val.tag === 'onConfirm') { this.approveByUuid(); }
    },
  },
  created() {
    console.log('this.$route.params', this.$route.params);
    const { applyUuid } = this.$route.query;

    this.isNotEdit = Boolean(applyUuid);
    if (this.isNotEdit) {
      this.applyUuid = applyUuid;
      this.getProApplyDetail();
    }
  },
  methods: {
    getProApplyDetail() {
      $gp_proApplyDetail({ applyUuid: this.applyUuid }).then((res) => {
        console.log(res);
        this.formData = res;
        if (res.applyStatus === 2) {
          this.formData.applyPass = true;
        } else if (res.status === 3) {
          this.formData.applyPass = false;
        }
        this.formData.files = res.fileList;
      });
    },
    formatState(state) {
      console.log('state==', state);
      const status = {
        1: '待审批',
        2: '已通过',
        3: '已拒绝',
      };
      return status[state];
    },
    formatStatus(status) {
      const state = {
        1: '建设中',
        2: '已暂停',
        3: '已完工',
      };
      return state[status] || '--';
    },
    formatType(pType) {
      const type = {
        1: '出土项目',
        2: '进土项目',
      };
      return type[pType] || '--';
    },
    approveByUuid() {
      const params = {
        applyUuid: this.applyUuid,
        applyPass: Boolean(this.formData?.applyPass),
        applyComment: this.formData?.applyComment,
      };
      if (!params.applyPass && !params.approveComment) {
        this.$message.warning('请填写审批意见');
        return;
      }
      $gp_proApply(params).then((res) => {
        console.log('res', res);
        this.$parent.getProList();
        this.$router.back();
      });
    },
    refreshData() {
      this.getProApplyDetail();
    },
  },
};
</script>

<style lang="scss" scoped>
  .input-item{
    width: 48%;
  }
  .submit-area{
    padding: 20px;
    text-align: center;
  }
</style>
