<!--
 * @Author: 许波
 * @Date: 2021-07-13 20:11:20
 * @LastEditTime: 2023-02-03 00:11:52
-->
<template>
  <div class="module-wrap" v-if="$route.name === 'AddQtc'">
     <el-form label-width="100px" :rules="rules" :model="formData">
       <div class="flex">
          <div class="flex-item">
            <el-form-item label="弃土场名称" prop="qtcName" required>
              <el-input v-model="formData.qtcName"></el-input>
            </el-form-item>
            <el-form-item label="联系人">
              <el-input v-model="formData.person"></el-input>
            </el-form-item>
            <el-form-item label="经度" prop="lng" required>
              <el-input v-model="formData.lng" @blur="handlerChange"></el-input>
            </el-form-item>
          </div>
          <div class="flex-item">
            <el-form-item label="登陆账号">
              <el-input v-model="formData.username"></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
              <el-input v-model="formData.phone"></el-input>
            </el-form-item>
            <el-form-item label="纬度" prop="lat" required>
              <el-input v-model="formData.lat" @blur="handlerChange"></el-input>
            </el-form-item>
          </div>
       </div>
        <el-form-item label="使用状态" prop="qtcStatus">
          <el-radio-group v-model="formData.qtcStatus">
            <el-radio :label="1">使用中</el-radio>
            <el-radio :label="3">已关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="弃土场地址" prop="address">
          <el-input v-model="formData.address"></el-input>
        </el-form-item>
        <Gmap ref='gmap' @handlePosition="handlePosition"></Gmap>
        <div class="flex fcc">
          <el-form-item class="mt20">
            <el-button type="primary" @click="createQtc">确定</el-button>
            <el-button @click="$router.back()">取消</el-button>
          </el-form-item>
        </div>
      </el-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Gmap from '../../components/Gmap.vue';
import {
  $gp_createQTC, $gp_getQtcInfo, $gp_editQtcInfo,
} from '../../api/index';
import { globalClose } from '../../utils/utils';

export default {
  name: 'AddQtc',
  components: {
    // TGdCustomMap,
    // Amap,
    Gmap,
  },
  data() {
    return {
      formData: {
        qtcStatus: 1,
        lng: '',
        lat: '',
      },
      rules: {
        qtcName: [{ required: true, message: '请输入弃土场名称', trigger: 'blur' }],
        lat: [{ required: true, message: '请输入纬度', trigger: 'blur' }],
        lng: [{ required: true, message: '请输入经度', trigger: 'blur' }],
        address: [{ required: true, message: '请输入弃土场地址', trigger: 'blur' }],
        // username: [{ required: true, message: '请输入登陆账号', trigger: 'blur' }],
        // contact: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        // person: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters(['updateTime']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  created() {
    console.log(this.$route.params);
    const { qtcUuid } = this.$route.params;
    if (qtcUuid) {
      this.getQtcInfo(qtcUuid);
    }
    // this.formData.qtcUuid = this.$router
  },

  methods: {

    validate() {
      const fields = Object.keys(this.rules);
      for (let i = 0; i < fields.length; i += 1) {
        if (!this.formData[fields[i]]) {
          this.$message.error(this.rules[fields[i]][0].message);
          return false;
        }
      }
      return true;
    },
    createQtc() {
      const checkResult = this.validate();
      if (!checkResult) return;
      // const after = () => {
      //   const { lng, lat, address } = this.formData;
      //   const position = [lng, lat];
      //   this.$refs.gmap.setMarker(position, address);
      // };
      if (this.formData.qtcUuid) {
        $gp_editQtcInfo(this.formData).then(() => {
          this.$storage.remove('QtcList');
          this.$parent.getQtcList();
          this.$router.back();
        });
      } else {
        $gp_createQTC(this.formData).then(() => {
          this.$storage.remove('QtcList');
          this.$parent.getQtcList();
          this.$router.back();
        });
      }
    },
    handlePosition(val) {
      console.log('handle', val);
      // const { lng, lat } = val;
      // this.formData.lng = lng;
      // this.formData.lat = lat;
    },
    handlerChange() {
      if (this.formData.lng && this.formData.lat) {
        this.$nextTick(() => {
          const position = [this.formData.lng, this.formData.lat];
          console.log(position);
          this.$refs.gmap.initMapToMarker(position, this.address);
        });
      }
    },
    getQtcInfo(qtcUuid) {
      $gp_getQtcInfo({ qtcUuid }).then((res) => {
        console.log(res);
        this.formData = res;
        const { address, lng, lat } = res;
        this.address = address;
        this.position = [lng, lat];
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.gmap.initMapToMarker(this.position, address);
        });
      });
    },

  },
};
</script>

<style lang="scss" scoped>
  .t-gd-custom-map {
    height: 300px !important;
    width: 100%;
    .amap-marker-label {
      color: #ff4739 !important;
      border: none !important;
      background-color: transparent !important;
      font-size: 0.8em !important;
    }
    .t-amap-info-window {
      background-color: white;

      p {
        margin: 0;
        color: #adadad;
        span:last-child {
          font-size: 0.8em;
        }
      }
    }
  }
</style>
