<!--
 * @Author: 许波
 * @Date: 2021-07-14 00:06:24
 * @LastEditTime: 2023-01-29 20:28:38
-->
<template>
  <div class="page-wrap">
    <div class="prant-com module-wrap" v-if="$route.name === 'UserManage'">
      <div class="pb10 flex flc">
        <el-button type="primary" @click="addUser()">
          添加用户
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        :header-cell-style="$rowClass"
        style="width: 100%"
      >
        <el-table-column prop="name" width="100" label="用户姓名" />
        <el-table-column prop="mobile" width="140" label="手机号码" />
        <el-table-column prop="username" width="140" label="登录账号" />
        <el-table-column prop="privilegeList" :formatter="formatRole" label="权限" />
        <el-table-column width="160" label="操作">
          <template slot-scope="scope">
            <div class="operation-btns">
              <span class="theme-color pointer" @click="addUser(scope.row)">修改</span>
              <span class="theme-color pointer"
              v-if="scope.row.username !== 'admin'"
              @click="delUser(scope.row.managerUuid)">删除</span>
              <span class="theme-color pointer" @click="resetPwd(scope.row.managerUuid)">重置密码</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-block">
        <el-pagination
          background
          :current-page.sync="search.page"
          @current-change="handlerCurPage"
          :total="count"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
      <el-dialog title="添加/修改用户" :visible.sync="isShowDailog" width="30%">
        <el-form
          ref="formData"
          :model="formData"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户姓名" prop="name" required>
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="mobile" required>
            <el-input v-model="formData.mobile"></el-input>
          </el-form-item>
          <el-form-item label="登录账号" prop="username" required>
            <el-input v-model="formData.username"></el-input>
          </el-form-item>
          <el-form-item label="用户权限" required>
            <el-select class="w-100"
            v-model="formData.codeList"
            multiple
            placeholder="请选择">
              <el-option
                v-for="item in rolList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isShowDailog = false">取 消</el-button>
          <el-button type="primary" @click="createUser">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  $gp_addAccount, $gp_delAccount, $gp_getGroupList,
  $gp_resetGroupUserPwd, $gp_editGroupUser, $gp_getGroupRole,
} from '../../api/index';
import { globalClose } from '../../utils/utils';

export default {
  name: 'UserManage',
  data() {
    return {
      isShowDailog: false,
      search: {
        page: 1,
        size: 10,
      },
      count: 0,
      formData: {
        codeList: [],
      },
      rules: {
        username: [{ required: true, message: '请输入用户姓名', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
        name: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
        codeList: [{ required: true, message: '请输选择用户权限', trigger: 'blur' }],
      },
      rolList: [],
      tableData: [],
    };
  },
  computed: {
    ...mapGetters(['updateTime']),
    isShowPage() {
      return (this.$route.name).startsWith('ResetPwd');
    },
  },

  watch: {
    updateTime(val) {
      console.log(val.routeName);
      console.log(this.$options.name);
      const [routeName] = val.routeName.split('-');
      if (routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  methods: {
    validate() {
      const fields = Object.keys(this.rules);
      for (let i = 0; i < fields.length; i += 1) {
        if (!this.formData[fields[i]]) {
          this.$message.error(this.rules[fields[i]][0].message);
          return false;
        }
        if (fields[i] === 'codeList' && !this.formData[fields[i]].length) {
          this.$message.error(this.rules[fields[i]][0].message);
          return false;
        }
      }
      return true;
    },
    addUser(userInfo) {
      console.log(userInfo);
      if (userInfo) {
        let formData = {};
        const { privilegeList } = userInfo;
        if (privilegeList.length) {
          const privilegeListCode = userInfo.privilegeList.map((role) => role.code);
          formData = {
            ...userInfo,
            codeList: privilegeListCode,
          };
        }
        this.formData = formData;
      }
      this.isShowDailog = true;
    },
    getGroupRoleList() {
      $gp_getGroupRole({}).then((res) => {
        this.rolList = res;
      });
    },
    createUser() {
      const checkResult = this.validate();
      if (!checkResult) return;
      if (this.formData.managerUuid) {
        console.log(this.formData);
        delete this.formData.privilegeList;
        $gp_editGroupUser(this.formData).then(() => {
          this.isShowDailog = false;
          this.formData = {};
          this.$message.success('修改成功');
          this.formData.codeList = [];
          this.$refs.formData.resetFields();
          this.getGroupList();
        });
      } else {
        $gp_addAccount(this.formData).then(() => {
          this.isShowDailog = false;
          this.$message.success('添加成功');
          this.formData.codeList = [];
          this.$refs.formData.resetFields();
          this.getGroupList();
        });
      }
    },
    getGroupList() {
      $gp_getGroupList(this.search).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
    formatRole({ privilegeList }) {
      if (privilegeList.length) {
        return privilegeList.map((role) => role.name).join('、');
      }
      return '';
    },
    refreshData() {
      this.getGroupList();
    },
    delUser(managerUuid) {
      this.$confirm('你确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $gp_delAccount({ managerUuid }).then(() => {
          this.$message.success('删除成功');
          this.getGroupList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作',
        });
      });
    },
    resetPwd(managerUuid) {
      this.$confirm('你确认要重置密码吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $gp_resetGroupUserPwd({ managerUuid }).then(() => {
          this.$message.success('重置密码成功');
          this.getGroupList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置密码',
        });
      });
    },
    handlerCurPage(val) {
      console.log(val);
      this.search.page = val;
      this.getGroupList();
    },

  },
  mounted() {
    this.getGroupRoleList();
    this.getGroupList();
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
</style>
