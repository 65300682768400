<!--
 * @Author: 许波
 * @Date: 2021-07-13 16:33:59
 * @LastEditTime: 2023-02-02 22:59:32
-->
<template>
  <div class="page-wrap">
    <div class="parent-com module-wrap" v-if="$route.name === 'ProjectManage'">
      <el-form
          :inline="true"
          label-width="90px"
          :model="formData"
        >
          <el-form-item label="项目状态">
            <el-select  class="w160" v-model="formData.projectStatus" placeholder="请选择项目状态">
              <el-option :label="item.label" v-for="item in regItems"
              :key="item.val" :value="item.val"/>
            </el-select>
          </el-form-item>
          <el-form-item label="项目类型">
            <el-select  class="w160" v-model="formData.projectType" placeholder="请选择项目状态">
              <el-option :label="item.label" v-for="item in proType"
              :key="item.val" :value="item.val"/>
            </el-select>
          </el-form-item>
          <el-form-item label="项目名称">
           <el-input v-model="formData.projectName" placeholder="请输入项目名称" clearable></el-input>
          </el-form-item>
          <el-form-item class="center">
            <el-button type="primary" @click="getProList">查询</el-button>
            <el-button type="primary" plain @click="resetData">重置</el-button>
          </el-form-item>
        </el-form>
        <div class="pb10 flex flc"><el-button type="primary" @click="goToAddPro()">
          添加项目
        </el-button></div>
        <el-table
          border
          :data="tableData"
          :header-cell-style="$rowClass"
          style="width: 100%">
          <!-- <el-table-column label="项目名称">
            <template slot-scope="scope">
              <span class="theme-color pointer" @click="goToProDetail(scope.row.projectUuid)">
                {{scope.row.projectName}}
              </span>
            </template>
          </el-table-column> -->
          <el-table-column prop="projectName" label="项目名称" />
          <el-table-column prop="customer" label="客户单位" />
          <el-table-column :formatter="formatStatus" label="项目状态" />
          <el-table-column prop="username" label="管理账号" />
          <el-table-column :formatter="formatType" label="项目类型" />
          <el-table-column prop="person" label="联系人" />
          <el-table-column prop="phone" label="联系电话" />
          <el-table-column label="操作" width="280">
            <template slot-scope="scope">
              <div class="operation-btns">
                <span class="theme-color pointer"
                @click="goToAddPro(scope.row.projectUuid)">编辑</span>
                <span class="theme-color pointer"
                @click="delPro(scope.row.projectUuid)">删除</span>
                <span class="theme-color pointer"
                @click="resetPwd(scope.row.projectUuid)">重置密码</span>
                <span class="theme-color pointer"
                @click="goToProSub(scope.row.projectUuid)">
                  管理子账号
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-block">
          <el-pagination
            background
            :current-page.sync="formData.page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            >
          </el-pagination>
        </div>
    </div>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { globalClose } from '../../utils/utils';
import {
  $gp_getProList, $gp_delProInfo, $gp_resetProPwd, $gp_proDevicePassword,
} from '../../api/index';

export default {
  name: 'ProjectManage',
  data() {
    return {
      formData: {
        projectStatus: null,
        projectType: null,
        projectName: null,
        page: 1,
        size: 10,
      },
      count: 0,
      tableData: [],
      regItems: [
        { label: '建设中', val: 1 },
        { label: '已暂停', val: 2 },
        { label: '已完工', val: 3 },
      ],
      proType: [
        { label: '出土项目', val: 1 },
        { label: '进土项目 ', val: 2 },
      ],
      page: 1,
      position: [],
      address: '',
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(['updateTime']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },

    handlerCurPage(val) {
      console.log(val);
      this.formData.page = val;
      this.getProList();
    },
    resetData() {
      this.formData.projectStatus = null;
      this.formData.projectType = null;
      this.formData.projectName = '';
    },
    goToAddPro(projectUuid) {
      if (projectUuid) {
        this.$router.push({ path: '/projectManage/addProject', query: { projectUuid } }); return;
      }
      this.$router.push({ name: 'AddProject' });
    },
    goToProDetail(projectUuid) {
      this.$router.push({ name: 'ProjectDetail', params: { projectUuid } });
    },
    goToProSub(projectUuid) {
      this.$router.push({ path: 'projectManage/addProjectSubAccount', query: { projectUuid } });
    },
    formatStatus({ projectStatus }) {
      const state = {
        1: '建设中',
        2: '已暂停',
        3: '已完工',
      };
      return state[projectStatus] || '--';
    },
    formatType({ projectType }) {
      const type = {
        1: '出土项目',
        2: '进土项目',
      };
      return type[projectType] || '--';
    },
    getProList() {
      if (this.formData.status === 1) {
        delete this.formData.status;
      }
      $gp_getProList(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
    refreshData() {
      this.getProList();
    },
    delPro(projectUuid) {
      this.$confirm('你确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $gp_delProInfo({ projectUuid }).then(() => {
          this.$message.success('删除成功');
          this.getProList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作',
        });
      });
    },
    resetPwd(projectUuid, type = 1) {
      let msg = '你确认要重置密码吗?';
      if (type === 2) { msg = '你确认要重置设备密码吗?'; }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const func = [$gp_resetProPwd, $gp_proDevicePassword];
        func[type - 1]({ projectUuid }).then(() => {
          this.$message.success('重置密码成功');
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置密码',
        });
      });
    },
  },
  mounted() {
    this.getProList();
  },
};
</script>

<style lang="scss" scoped>
  .operation-btns{
    span{
      padding-right: 5px;
    }
  }
</style>
