<!--
 * @Author: 许波
 * @Date: 2021-07-10 12:43:50
 * @LastEditTime: 2023-02-09 23:28:01
-->
<template>
  <div class="main">
    <div class="print-com module-wrap" v-if="$route.name === 'EntraceManage'">
      <el-form
        :inline="true"
        :label-position="labelPosition"
        label-width="90px"
        :model="formData"
      >
        <el-form-item label="年份">
        <el-select class="w140" v-model="formData.year" placeholder="请选择年份">
          <el-option
            :label="year.label"
            :value="year.value"
            v-for="year in years"
            :key="year.value"
          />
        </el-select>
        </el-form-item>
        <el-form-item label="月份">
          <el-select
            class="w140"
            v-model="formData.month"
            placeholder="请选择月份"
            @change="getDays"
          >
            <el-option
              :label="month.label"
              :value="month.value"
              v-for="month in months"
              :key="month.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-select class="w140" v-model="formData.day" placeholder="请选择日期">
            <el-option :label="day.label" :value="day.value" v-for="day in days" :key="day.value" />
          </el-select>
        </el-form-item>
        <el-form-item class="center">
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="primary" plain @click="resetFormData">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="table-data">
        <div class="count-detail theme-color pointer" @click="toDetail">统计明细</div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="按项目统计" name="first">
              <el-table
                :data="tableData"
                border
                show-summary
                :header-cell-style="$rowClass"
                style="width: 100%"
              >
                <el-table-column prop="projectName" label="项目名称" />
                <el-table-column prop="times" label="入场次数" />
                <el-table-column prop="capacity" label="弃土方量" />
              </el-table>
              <div class="pagination-block">
                <el-pagination
                  background
                  :current-page.sync="page"
                  @current-change="handlerCurPage"
                  :total="count"
                  :page-size="10"
                  layout="total, prev, pager, next, jumper"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="按施工方统计" name="second">
              <el-table
                :data="tableData"
                border
                show-summary
                :header-cell-style="$rowClass"
                style="width: 100%"
              >
                <el-table-column prop="sgfName" label="施工方" />
                <el-table-column prop="times" label="入场次数" />
                <el-table-column prop="capacity" label="弃土方量" />
              </el-table>
              <div class="pagination-block">
                <el-pagination
                  background
                  :current-page.sync="page"
                  @current-change="handlerCurPage"
                  :total="count"
                  :page-size="10"
                  layout="total, prev, pager, next, jumper"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="按车辆统计" name="third">
              <el-table
                :data="tableData"
                border
                show-summary
                :header-cell-style="$rowClass"
                style="width: 100%"
              >
                <el-table-column prop="carLicense" label="车牌号" />
                <el-table-column prop="times" label="入场次数" />
                <el-table-column prop="capacity" label="弃土方量" />
              </el-table>
              <div class="pagination-block">
                <el-pagination
                  background
                  :current-page.sync="page"
                  @current-change="handlerCurPage"
                  :total="count"
                  :page-size="10"
                  layout="total, prev, pager, next, jumper"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
      </div>
    </div>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  createYears, createMonths, createDays,
} from '../../utils/index';
import { $gp_qtcTransListBySgf, $gp_qtcTransListByCar, $gp_qtcTransListByPro } from '../../api/qtc';

export default {
  name: 'EntraceManage',
  data() {
    return {
      labelPosition: 'right',
      activeName: 'first',
      count: 5,
      page: 1,
      formData: {
        page: 0,
        size: 10,
        year: '',
        month: '',
        day: '',
      },
      years: [],
      days: [],
      months: [],
      tableData: [],
    };
  },
  computed: {
    ...mapGetters(['updateTime']),
  },
  watch: {
    updateTime(val) {
      console.log('watch', val);
      if (val.routeName === this.$options.name) {
        console.log('watch1', val);
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { this.$router.push({ name: 'Home' }); }
      }
    },
  },
  created() {
    this.years = createYears(5);
    this.months = createMonths();
    this.getStartDate();
    // this.getTableData();
  },
  methods: {
    getDays() {
      this.days = createDays(this.formData.month, this.formData.year);
    },
    resetFormData() {
      this.formData = {
        page: 0,
        size: 10,
        year: '',
        month: '',
        day: '',
      };
      this.getStartDate();
    },
    getStartDate() {
      this.getDays();
      const date = new Date();
      this.formData.year = this.years[0].value;
      this.formData.month = date.getMonth();
      this.formData.day = 1;
    },
    search() {
      console.log('search');
      // this.getTableData();
    },
    handlerCurPage(val) {
      this.page = val - 1;
      // this.getTableData();
    },
    handleSizeChange(val) {
      console.log(val);
    },
    refreshData() {
      console.log('TicketRecord');
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.activeName = tab.name;
      this.formData.page = 0;
      // this.getTableData();
    },
    toDetail() {
      console.log('todetail');
      this.$router.push({ name: 'EntraceManageDetail' });
    },
    getTableData() {
      let func = $gp_qtcTransListByPro;
      if (this.activeName === 'second') {
        func = $gp_qtcTransListBySgf;
      }
      if (this.activeName === 'third') {
        func = $gp_qtcTransListByCar;
      }
      const {
        size, page, year, month, day,
      } = this.formData;
      const d = day < 10 ? `0${day}` : day;
      const params = {
        size,
        page,
        startDate: `${year}-${month}-${d}`,
      };
      func(params).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-item {
  width: 48%;
}
.submit-area {
  padding: 20px;
  text-align: center;
}
.table-data{
  position: relative;
  .count-detail{
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 10;
  }
}
</style>
