<!--
 * @Author: 许波
 * @Date: 2021-07-13 21:18:42
 * @LastEditTime: 2023-01-28 01:07:17
-->
<template>
  <div class="page-wrap module-wrap" v-if="$route.name === 'ProjectDetail'">
    <h2 class="flex fcc fontb">{{proInfo.projectName}}</h2>
    <div class="el-tabs-box">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="first">
            <div class="module-wrap">
              <el-form label-width="130px">
                <div class="flex">
                    <div class="flex-item">
                      <el-form-item label="项目名称：" prop="name" required>
                        {{proInfo.projectName}}
                      </el-form-item>
                      <el-form-item label="联系人：" prop="name">
                        {{proInfo.person}}
                      </el-form-item>
                      <el-form-item label="项目状态">
                        {{formatStatus(proInfo.projectStatus)}}
                      </el-form-item>
                      <el-form-item label="客户名称：" prop="name">
                        {{proInfo.customer}}
                      </el-form-item>
                    </div>
                    <div class="flex-item">
                      <el-form-item label="登陆账号：" prop="name">
                        {{proInfo.username}}
                      </el-form-item>
                      <el-form-item label="联系电话：" prop="name">
                        {{proInfo.phone}}
                      </el-form-item>
                      <el-form-item label="项目类型" prop="name">
                        {{formatType(proInfo.projectType)}}
                      </el-form-item>
                    </div>
                </div>
                  <el-form-item label="项目地址：" prop="address">
                    {{proInfo.address}}(经度：{{proInfo.lng }} 纬度{{ proInfo.lat }})
                  </el-form-item>
                </el-form>
                <Gmap ref='gmap'></Gmap>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Gmap from '../../components/Gmap.vue';
import {
  $gp_getProInfo, $gp_groupRelation,
} from '../../api/index';
import { globalClose } from '../../utils/utils';

export default {
  name: 'ProjectDetail',
  components: { Gmap },
  data() {
    return {
      proInfo: {},
      tableData: [],
      formData: {
        page: 0,
        size: 10,
      },
      count: 0,
      page: 1,
      position: [],
      address: '',
      activeName: 'first',
      projectUuid: '',
    };
  },
  computed: {
    ...mapGetters(['updateTime']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(this.projectUuid); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  created() {
    console.log(this.$route.params);
    const { projectUuid } = this.$route.params;
    this.projectUuid = projectUuid;
    this.getProInfo(projectUuid);
  },
  methods: {
    refreshData(projectUuid) {
      this.getProInfo(projectUuid);
    },
    getProInfo(projectUuid) {
      $gp_getProInfo({ projectUuid }).then((res) => {
        this.proInfo = res;
        const { address, lng, lat } = res;
        console.log(address, lng, lat);
        this.address = address;
        this.position = [lng, lat];
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.gmap.initMapToMarker(this.position, address);
        });
      });
    },
    formatType(projectType) {
      const type = {
        1: '出土项目',
        2: '进土项目',
      };
      return type[projectType] || '--';
    },
    formatStatus(projectStatus) {
      const state = {
        1: '建设中',
        2: '已暂停',
        3: '已完工',
      };
      return state[projectStatus] || '--';
    },
    getSgfList() {
      const { page, size } = this.formData;
      $gp_groupRelation({ page, size, projectUuid: this.projectUuid }).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
    handlerCurPage(val) {
      console.log(val);
      this.formData.page = val - 1;
      this.getSgfList();
    },
    handleClick(val) {
      console.log(val);
      this.formData.page = 0;
      if (val.paneName === 'second') {
        this.getSgfList();
      }
    },
  },
};
</script>

<style>

</style>
