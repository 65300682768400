<!--
 * @Author: 许波
 * @Date: 2021-07-13 16:33:59
 * @LastEditTime: 2023-02-16 00:21:48
-->
<template>
  <div class="page-wrap">
    <div class="parent-com module-wrap" v-if="$route.name === 'ProjectManage'">
      <el-form
          :inline="true"
          label-width="90px"
          :model="formData"
        >
          <el-form-item label="申请状态">
            <el-select  class="w160" v-model="formData.applyStatus" placeholder="请选择项目状态">
              <el-option :label="item.label" v-for="item in regItems"
              :key="item.val" :value="item.val"/>
            </el-select>
          </el-form-item>
          <el-form-item label="项目名称">
           <el-input v-model="formData.projectName" placeholder="请输入项目名称" clearable></el-input>
          </el-form-item>
          <el-form-item class="center">
            <el-button type="primary" @click="getProList">查询</el-button>
            <el-button type="primary" plain @click="resetData">重置</el-button>
          </el-form-item>
        </el-form>
        <div class="pb10 flex flc"><el-button type="primary" @click="goToAddPro()">
          申请修改项目状态
        </el-button></div>
        <el-table
          border
          :data="tableData"
          :header-cell-style="$rowClass"
          style="width: 100%">
          <el-table-column label="申请编号" width="100">
            <template slot-scope="scope">
              <span class="theme-color pointer"
              @click="goToProDetail(scope.row.applyUuid, 'onlyBack')">
                {{scope.row.no}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="projectName" label="项目名称" />
          <el-table-column prop="customer" label="客户单位" />
          <el-table-column :formatter="formatStatus" label="状态变更" />
          <el-table-column :formatter="formatType" label="项目类型变更" />
          <el-table-column :formatter="formatClear" width="110" label="是否清零票据" />
          <el-table-column :formatter="formatState" label="申请状态">
            <template slot-scope="scope">
              <span :class="formatState(scope.row).style">{{ formatState(scope.row).label }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-block">
          <el-pagination
            background
            :current-page.sync="formData.page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            >
          </el-pagination>
        </div>
    </div>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { globalClose } from '../../utils/utils';
import {
  $gp_proApplyPage, $gp_delProInfo, $gp_resetProPwd, $gp_proDevicePassword,
} from '../../api/index';

export default {
  name: 'ProjectManage',
  data() {
    return {
      formData: {
        applyStatus: null,
        projectName: null,
        page: 1,
        size: 10,
      },
      count: 0,
      tableData: [],
      regItems: [
        { label: '待审批', val: 1 },
        { label: '已通过', val: 2 },
        { label: '已拒绝', val: 3 },
      ],
      page: 1,
      position: [],
      address: '',
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(['updateTime']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },

    handlerCurPage(val) {
      console.log(val);
      this.formData.page = val;
      this.getProList();
    },
    resetData() {
      this.formData.applyStatus = null;
      this.formData.projectName = null;
    },
    goToAddPro() {
      this.$router.push({ name: 'CreateProApply' });
    },
    goToProDetail(applyUuid, onlyBack) {
      this.$router.push({ path: '/projectStatusManage/createProApply', query: { applyUuid, onlyBack } });
    },
    goToProSub(projectUuid) {
      this.$router.push({ path: '/projectStatusManage/addProjectSubAccount', query: { projectUuid } });
    },
    formatStatus({ oldStatus, newStatus }) {
      const state = {
        1: '建设中',
        2: '已暂停',
        3: '已完工',
      };
      return `${state[oldStatus]} → ${state[newStatus]}`;
    },
    formatType({ oldType, newType }) {
      const type = {
        1: '出土项目',
        2: '进土项目',
      };
      return `${type[oldType]} → ${type[newType]}`;
    },
    formatState({ applyStatus }) {
      const state = {
        1: { label: '待审批', style: 'blue' },
        2: { label: '已通过', style: 'theme-color' },
        3: { label: '未通过', style: 'red' },

      };
      return state[applyStatus];
    },
    formatClear({ couponClear }) {
      return couponClear ? '是' : '否';
    },
    getProList() {
      if (this.formData.status === 1) {
        delete this.formData.status;
      }
      $gp_proApplyPage(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
    refreshData() {
      this.getProList();
    },
    delPro(projectUuid) {
      this.$confirm('你确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $gp_delProInfo({ projectUuid }).then(() => {
          this.$message.success('删除成功');
          this.getProList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作',
        });
      });
    },
    resetPwd(projectUuid, type = 1) {
      let msg = '你确认要重置密码吗?';
      if (type === 2) { msg = '你确认要重置设备密码吗?'; }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const func = [$gp_resetProPwd, $gp_proDevicePassword];
        func[type - 1]({ projectUuid }).then(() => {
          this.$message.success('重置密码成功');
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置密码',
        });
      });
    },
  },
  mounted() {
    this.getProList();
  },
};
</script>

<style lang="scss" scoped>
  .operation-btns{
    span{
      padding-right: 5px;
    }
  }
</style>
