<!--
 * @Author: 许波
 * @Date: 2021-07-26 23:08:29
 * @LastEditTime: 2022-11-25 21:50:47
-->
<template>
  <div class="entrance-marange-detail-wrap">
    <div class="module-wrap pbn">
      <el-form :inline="true" :label-position="labelPosition" label-width="120px" :model="formData">
        <div class="input-area flex fsbc">
          <div>
            <el-form-item label="年份">
              <el-select class="w140" v-model="formData.year" placeholder="请选择年份">
                <el-option :label="year.label"
                :value="year.value" v-for="year in years"
                :key="year.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="月份">
              <el-select class="w140" v-model="formData.month"
              @change="getDays" placeholder="请选择月份">
                <el-option :label="month.label"
                :value="month.value" v-for="month in months"
                :key="month.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="日期">
              <el-select  class="w140" v-model="formData.day" placeholder="请选择日期">
                <el-option :label="day.label"
                :value="day.value" v-for="day in days"
                :key="day.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="项目">
              <el-select  class="w140" v-model="formData.projectUuid" placeholder="请选择项目">
                <el-option :label="item.name" v-for="item in proList"
                :key="item.uuid" :value="item.uuid"/>
              </el-select>
            </el-form-item>
            <el-form-item label="施工方">
              <el-select  class="w140" v-model="formData.sgfUuid" placeholder="请选择施工方">
                <el-option :label="item.name" v-for="item in sgfList"
                :key="item.uuid" :value="item.uuid"/>
              </el-select>
            </el-form-item>
            <el-form-item label="车牌号">
              <el-input v-model="formData.carLicense" placeholder="请输入车牌号" class="w140"/>
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <el-button type="primary" @click="search">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain @click="resetData">重置</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="module-wrap">
      <el-table
        :data="tableData"
        border
        show-summary
        :summary-method="getSummaries"
        :header-cell-style="$rowClass"
        style="width: 100%"
      >
        <el-table-column prop="carLicense" label="车牌号" />
        <el-table-column prop="sgfName" label="施工方" />
        <el-table-column prop="c" label="项目名称" />
        <el-table-column prop="times" label="入场次数" />
        <el-table-column prop="capacity" label="弃土方量" />
      </el-table>
      <div class="pagination-block">
        <el-pagination
          background
          :current-page.sync="page"
          @current-change="handlerCurPage"
          :total="count"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  createYears,
  createMonths,
  createDays,
  globalClose,
  getSumData,
} from '../../utils/index';
import { $gp_qtcTransListStatistics, $gp_groupRelation, $gp_getProList } from '../../api/index';

export default {
  name: 'EntraceRecored',
  data() {
    return {
      labelPosition: 'right',
      count: 5,
      page: 1,
      formData: {
        page: 0,
        size: 10,
        year: '',
        month: '',
        day: '',
      },
      sgfList: [],
      tableData: [],
      proList: [],
      qtcList: [],
      days: [],
      years: [],
      months: [],
      transportState: [
        { label: '全部', val: null },
        { label: '运输中', val: false },
        { label: '已完成', val: true },
      ],
    };
  },
  mounted() {
    this.years = createYears(5);
    this.months = createMonths();
    this.getStartDate();
    this.getSgfList();
    this.getProList();
    this.groupTransList();
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  methods: {
    search() {
      console.log('search');
      const { carLicense } = this.formData;
      if (!carLicense) delete this.formData.carLicense;
      this.groupTransList();
    },
    getStartDate() {
      this.getDays();
      const date = new Date();
      this.formData.year = this.years[0].value;
      this.formData.month = date.getMonth();
      this.formData.day = 1;
    },
    getSummaries(params) {
      return getSumData(params);
    },
    handlerCurPage(val) {
      console.log('val', val);
      this.formData.page = val - 1;
      this.groupTransList();
    },
    resetData() {
      this.formData = {
        page: 0,
        size: 10,
        year: '',
        month: '',
        day: '',
      };
      this.getStartDate();
    },
    getProList() {
      $gp_getProList({ size: 100 }).then((res) => {
        this.proList = res.list;
      });
    },
    // 获取已经登记的施工方列表
    getSgfList() {
      $gp_groupRelation({ size: 100 }).then((res) => {
        this.sgfList = res.list;
      });
    },
    handleSizeChange(val) {
      console.log(val);
    },
    getDays() {
      this.days = createDays(this.formData.month, this.formData.year);
    },
    groupTransList() {
      $gp_qtcTransListStatistics(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-area {
  width: 1000px;
}
</style>
