<!--
 * @Author: 许波
 * @Date: 2021-07-13 16:33:59
 * @LastEditTime: 2023-02-11 12:29:45
-->
<template>
  <div class="page-wrap">
    <div class="parent-com module-wrap" v-if="$route.name === 'QtcManage'">
      <el-form
          :inline="true"
          label-width="90px"
          :model="formData"
        >
          <el-form-item label="使用状态">
            <el-select  class="w160" v-model="formData.qtcStatus" placeholder="全部">
              <el-option :label="item.label" v-for="item in regItems"
              :key="item.val" :value="item.val"/>
            </el-select>
          </el-form-item>
          <el-form-item label="弃土场名称">
           <el-input v-model="formData.qtcName" placeholder="请输入弃土场名称" clearable></el-input>
          </el-form-item>
          <el-form-item class="center">
            <el-button type="primary" @click="getQtcList">查询</el-button>
            <el-button type="primary" plain @click="resetData">重置</el-button>
          </el-form-item>
        </el-form>
        <div class="pb10 flex flc"><el-button type="primary" @click="goToAddQtc()">
          添加弃土场
        </el-button></div>
        <el-table
          :data="tableData"
          border
          :header-cell-style="$rowClass"
          style="width: 100%">
          <el-table-column prop="qtcName" label="弃土场名称">
            <template slot-scope="scope">
              <span class="theme-color pointer" @click="goToQtcInfo(scope.row.qtcUuid)">
                {{scope.row.qtcName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="弃土场地址">
            <template slot-scope="scope">
              <span class="theme-color pointer" @click="goToProDetail(scope.row)">
                {{scope.row.address}}</span>
            </template>
          </el-table-column>
          <el-table-column :formatter="formatStatus" label="使用状态" />
          <el-table-column prop="username" label="登录账号" />
          <el-table-column prop="person" label="联系人" />
          <el-table-column prop="phone" label="联系电话" />
          <el-table-column label="操作" width="160">
            <template  slot-scope="scope">
              <div class="operation-btns">
                <span class="theme-color pointer" @click="goToAddQtc(scope.row.qtcUuid)">编辑</span>
                <span class="theme-color pointer" @click="delQtc(scope.row.qtcUuid)">删除</span>
                <span class="theme-color pointer" @click="resetPwd(scope.row.qtcUuid)">重置密码</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-block">
          <el-pagination
            background
            :current-page.sync="page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            >
          </el-pagination>
        </div>
        <el-dialog
          :title="`查看地点 （编号：${curUuid}）`"
          :visible.sync="dialogVisible"
          width="80%">
          <div class="address">{{address}}</div>
          <div class="qtc-map">
            <Gmap ref="gmap" :position="position" :address="address"></Gmap>
          </div>
        </el-dialog>
    </div>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { globalClose } from '../../utils/utils';
import {
  $gp_getQtcList, $gp_delQtcInfo, $gp_resetQtcPwd, $gp_devicePassword,
} from '../../api/index';
import Gmap from '../../components/Gmap.vue';

export default {
  name: 'QtcManage',
  components: { Gmap },
  data() {
    return {
      formData: {
        qtcStatus: null,
        qtcName: null,
        page: 1,
        size: 10,
      },
      count: 0,
      tableData: [],
      regItems: [
        { label: '全部', val: null },
        { label: '使用中', val: 1 },
        { label: '已关闭', val: 3 },
      ],
      page: 1,
      position: [],
      address: '',
      dialogVisible: false,
      curUuid: '',
    };
  },
  computed: {
    ...mapGetters(['updateTime']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSizeChange(val) {
      console.log(val);
    },
    handlerCurPage(val) {
      console.log(val);
      this.formData.page = val;
      this.getQtcList();
    },
    resetData() {
      this.formData.qtcStatus = null;
      this.formData.qtcName = null;
    },
    goToAddQtc(qtcUuid) {
      if (qtcUuid) {
        this.$router.push({ name: 'AddQtc', params: { qtcUuid } }); return;
      }
      this.$router.push({ name: 'AddQtc' });
    },
    goToQtcInfo(qtcUuid) {
      this.$router.push({ path: '/qtcManage/qtcInfo', query: { qtcUuid } });
    },
    goToProDetail({
      address, lng, lat, qtcUuid,
    }) {
      console.log(address, lng, lat);
      this.address = address;
      this.position = [lng, lat];
      this.dialogVisible = true;
      this.curUuid = qtcUuid;
      this.$nextTick(() => {
        this.$refs.gmap.initMap();
      });
    },
    formatStatus({ qtcStatus }) {
      const state = {
        1: '使用中',
        3: '已关闭',
      };
      return state[qtcStatus];
    },
    getQtcList() {
      $gp_getQtcList(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
    refreshData() {
      this.getQtcList();
    },
    delQtc(qtcUuid) {
      this.$confirm('你确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $gp_delQtcInfo({ qtcUuid }).then(() => {
          this.$message.success('删除成功');
          this.getQtcList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作',
        });
      });
    },
    resetPwd(qtcUuid, type = 1) {
      console.log(type);
      let msg = '你确认要重置密码吗?';
      if (type === 2) { msg = '你确认要重置设备密码吗?'; }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const funcs = [$gp_resetQtcPwd, $gp_devicePassword];
        funcs[type - 1]({ qtcUuid }).then(() => {
          this.$message.success('重置密码成功');
          this.getQtcList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置密码',
        });
      });
    },
  },
  mounted() {
    this.getQtcList();
  },
};
</script>

<style lang="scss" scoped>
  .address{
    margin-top: -20px;
    margin-bottom: 20px;
  }
</style>
