<!--
 * @Author: 许波
 * @Date: 2021-07-11 17:44:13
 * @LastEditTime: 2022-04-03 23:36:13
 * @LastEditors: Please set LastEditors
 * @FilePath: \data-admin\src\views\ticket\ticketCoutn.vue
-->
<template>
  <div class="page-wrap">
    <div class="module-wrap" v-if="$route.name=='SgfManage'">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="已登记" name="first">
          <el-table
            :data="tableData"
            border
            :header-cell-style="$rowClass"
            style="width: 100%">
            <el-table-column width="140" :formatter="formatCheckinTime" label="登记时间" />
            <el-table-column prop="sgfUsername" label="登录账号" />
            <el-table-column prop="name" show-overflow-tooltip label="施工方名称" />
            <el-table-column prop="workScope" show-overflow-tooltip label="施工内容" />
            <el-table-column width="100" prop="person" label="联系人" />
            <el-table-column width="130" prop="contact" label="联系方式" />
            <el-table-column width="130" label="操作">
              <template slot-scope="scope">
                <div class="operation-btns">
                  <span class="theme-color pointer"
                  @click="recordOperation(scope.row.uuid, 1)">移除</span>
                  <span class="theme-color pointer"
                  @click="recordOperation(scope.row.sgfUuid, 2)">重置密码</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-block">
            <el-pagination
              background
              :current-page.sync="page"
              @current-change="handlerCurPage"
              :total="count"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="待我审核" name="second">

          <el-table
            :data="tableData"
            border
            :header-cell-style="$rowClass"
            style="width: 100%">
            <el-table-column width="140" :formatter="formatApplyTime" label="提交时间" />
            <el-table-column prop="sgfUsername" label="登录账号" />
            <el-table-column prop="name" show-overflow-tooltip label="施工方名称" />
            <el-table-column prop="workScope" show-overflow-tooltip label="施工内容">
              <!-- <template slot-scope="scope">
                <el-link type="primary">{{scope.row.workScope}}</el-link>
              </template> -->
            </el-table-column>
            <el-table-column width="100" prop="person" label="联系人" />
            <el-table-column width="130" prop="contact" label="联系电话" />
            <el-table-column width="130" label="操作" >
              <template slot-scope="scope">
                <div class="operation-btns">
                  <span class="theme-color pointer" @click="proApprove(scope.row.uuid, true)">
                    通过
                  </span>
                  <span class="red pointer" @click="proApprove(scope.row.uuid, false)">拒绝</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-block">
            <el-pagination
              background
              :current-page.sync="page"
              @current-change="handlerCurPage"
              :total="count"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
              >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="待集团审核" name="third">
          <el-table
            :data="tableData"
            border
            :header-cell-style="$rowClass"
            style="width: 100%">
            <el-table-column prop="name" label="施工方名称" />
            <el-table-column prop="workScope" show-overflow-tooltip label="施工内容">
              <!-- <template>
                <el-link type="primary">这里是施工内容描述</el-link>
              </template> -->
            </el-table-column>
            <el-table-column prop="person" label="联系人" />
            <el-table-column prop="contact" label="联系电话" />
            <el-table-column width="130" label="操作" >
              <template slot-scope="scope">
                <span class="red pointer" @click="proApprove(scope.row.uuid, false)">拒绝</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-block">
            <el-pagination
              background
              :current-page.sync="page"
              @current-change="handlerCurPage"
              :total="count"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
         <el-tab-pane label="已拒绝/已移除" name="four">
          <el-table
            :data="tableData"
            border
            :header-cell-style="$rowClass"
            style="width: 100%">
            <el-table-column :formatter="formatBreakTime" label="拒绝/移除时间" />
            <el-table-column prop="operatorName" label="操作方" />
            <el-table-column prop="sgfName" label="被移除施工方" />
            <el-table-column prop="workScope" label="施工内容">
              <!-- <template>
                <el-link type="primary">这里是施工内容描述</el-link>
              </template> -->
            </el-table-column>
            <el-table-column prop="person" label="联系人" />
            <el-table-column prop="contact" label="联系电话" />
            <!-- <el-table-column width="130" label="操作" >
              <template slot-scope="scope">
                <div class="operation-btns">
                  <span class="red pointer" @click="recordOperation(scope.row.uuid)">删除</span>
                </div>
              </template>
            </el-table-column> -->
          </el-table>
          <div class="pagination-block">
            <el-pagination
              background
              :current-page.sync="page"
              @current-change="handlerCurPage"
              :total="count"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {
  $gp_proApprove, // 审批登记申请
  $gp_proDelBreakLog, // 删除已移除/已拒绝日志
  // $gp_proApplyDetail, // 获取待审核的登记申请详情
  $gp_proRestSgfPwd,
  $gp_proRemoveSgfPwd,
  $gp_profApplyList, // 获取待审核的登记申请列表
  $gp_proApplyGroupList, // 获取项目已审核通过，待集团审核的登记申请列表
  $gp_proBreakList, // 获取已拒绝/已移除列表
  $gp_proCheckinList, // 获取已登记的施工方列表
} from '../../api/index';
import { formatDate } from '../../utils/index';

export default {
  name: 'SgfManage',
  data() {
    return {
      visable: 1,
      count: 4,
      page: 1,
      params: {
        page: 0,
        size: 10,
      },
      tableData: [],
      activeName: 'first',
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      this.activeName = tab.name;
      this.params.page = 0;
      this.getTableData();
    },
    handlerCurPage(val) {
      this.params.page = val - 1;
      this.getTableData();
    },
    formatCheckinTime({ checkInTime }) {
      return formatDate(checkInTime);
    },
    formatApplyTime({ applyTime }) {
      return formatDate(applyTime);
    },
    formatBreakTime({ breakTime }) {
      return formatDate(breakTime);
    },
    getTableData() {
      let func = $gp_proCheckinList;
      if (this.activeName === 'second') {
        func = $gp_profApplyList;
      }
      if (this.activeName === 'third') {
        func = $gp_proApplyGroupList;
      }
      if (this.activeName === 'four') {
        func = $gp_proBreakList;
      }
      func(this.params).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
    proApprove(uuid, result) {
      let errMsg = '你确认拒绝吗？';
      if (result) {
        errMsg = '你确认通过吗？';
      }
      this.$confirm(errMsg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $gp_proApprove({ uuid, result }).then(() => {
          this.$message.success('操作成功');
          this.getTableData();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作',
        });
      });
    },
    recordOperation(uuid, type) {
      let func = $gp_proDelBreakLog;
      let msg = '你确认删除吗?';
      if (type === 1) {
        func = $gp_proRemoveSgfPwd;
        msg = '你确认移除吗？';
      }
      if (type === 2) {
        func = $gp_proRestSgfPwd;
        msg = '你确认重置密码吗？';
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        func({ uuid }).then(() => {
          this.$message.success('操作成功');
          this.getTableData();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .w500{
    width: 500px;
  }
  .operation-btns{
    span{
      padding-right: 10px;
    }
  }
</style>
